import { InfoOutlined } from '@mui/icons-material';
import { Drawer, Stack } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { useState } from 'react';
import { SideDrawerTable, SideDrawerTableData } from 'shared/components';
import { useService } from '@price-for-profit/service-provider';
import { PriceSummary } from 'shared/types';
import { formatSlashDate } from 'shared/utility';
import { mapBookPriceLastChange } from 'shared/utility/side-drawer-maps';

interface BookPriceLastChangeColProps {
    gridParams: GridRenderCellParams<PriceSummary, string>;
    exchangeRate: number;
}

export function BookPriceLastChangeCol(props: BookPriceLastChangeColProps) {
    const value = props.gridParams.row;
    const { submissionHistoryService } = useService();
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [drawerData, setDrawerData] = useState<SideDrawerTableData[]>();
    const [drawerLoading, setDrawerLoading] = useState<boolean>(false);

    async function onClickInfoButton(): Promise<void> {
        setDrawerOpen(true);
        setDrawerLoading(true);

        const submissionHistories = await submissionHistoryService.getLastSubmissionHistories(value.id);

        setDrawerData(mapBookPriceLastChange(submissionHistories, props.exchangeRate));
        setDrawerLoading(false);
    }

    return (
        <Stack flexDirection='row' alignItems='center'>
            <Drawer anchor='right' open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                <SideDrawerTable tableData={drawerData} loading={drawerLoading} />
            </Drawer>
            <InfoOutlined
                sx={{ color: 'darkgray', marginRight: 1, cursor: 'pointer' }}
                onClick={() => onClickInfoButton()}
            />
            {formatSlashDate(new Date(value.bookPriceLastChange))}
        </Stack>
    );
}
