import { Alert, LinearProgress, Paper, Stack } from '@mui/material';
import {
    DataGridPremium,
    GridFilterModel,
    GridLogicOperator,
    GridPinnedColumnFields,
    GridSlots,
    GridSortModel,
    useGridApiRef,
} from '@mui/x-data-grid-premium';
import { PAGE_SIZE_OPTIONS, useI2pServerDataGrid } from '@price-for-profit/data-grid';
import { useService } from '@price-for-profit/service-provider';
import { useCallback, useMemo } from 'react';
import { CurrencyToggle } from 'shared/components';
import { QUERYKEY_SURCHARGE } from 'shared/constants';
import {
    useEventStatusCreate,
    useEventTriggerAdfPipeline,
    useMassActionCostMaster,
    useSaveSurchargeMasterRow,
} from 'shared/mutations';
import { useGetExchangeRates, useGetMassActionEnabled } from 'shared/queries';
import { CostMasterMassActionParameters, Surcharge } from 'shared/types';
import { generateCorrelationId } from 'shared/utility';
import {
    SurchargeMasterColumns,
    SurchargeMasterTableToolbar,
    surchargeMasterColumnVisibilityInitialState,
    useSurchargeMasterTableState,
} from './table-def';

interface SurchargeMasterTableProps {
    user: drive.UserInfo;
    preferredExchangeRate: number;
}

export function SurchargeMasterTable({ user, preferredExchangeRate }: SurchargeMasterTableProps) {
    const apiRef = useGridApiRef();
    const { surchargeService, costService } = useService();
    const tableState = useSurchargeMasterTableState();

    const exchangeRates = useGetExchangeRates().data;

    const saveSurchargeChanges = useSaveSurchargeMasterRow();
    const massActionMutation = useMassActionCostMaster();
    const triggerAdfMutation = useEventTriggerAdfPipeline();
    const createEventStatus = useEventStatusCreate();

    const massActionEnabled = useGetMassActionEnabled();

    const surchargeMasterColumnInit = useMemo(() => {
        return {
            user,
            exchangeRate: preferredExchangeRate,
        };
    }, [user, preferredExchangeRate]);

    const setCurrencyType = (newCurrencyType: 'usd' | 'cad') => {
        const columnVisibilityModel = { ...tableState.columns.columnVisibilityModel.get() };

        surchargeMasterColumnInit.exchangeRate = exchangeRates ? exchangeRates[newCurrencyType] : 1.0;

        apiRef.current.updateColumns(SurchargeMasterColumns(surchargeMasterColumnInit));

        if (columnVisibilityModel) {
            apiRef.current.setColumnVisibilityModel(columnVisibilityModel);
        }
    };

    const surchargeMasterColumns = useMemo(() => {
        return SurchargeMasterColumns(surchargeMasterColumnInit);
    }, [surchargeMasterColumnInit]);

    const { getDataGridProps, state } = useI2pServerDataGrid<Surcharge>({
        columns: surchargeMasterColumns,
        name: QUERYKEY_SURCHARGE,
        dataGridInitialState: {
            columns: {
                columnVisibilityModel: surchargeMasterColumnVisibilityInitialState,
            },
            pinnedColumns: tableState.pinnedColumns.value as GridPinnedColumnFields,
        },
        pageSizeOptions: [...PAGE_SIZE_OPTIONS, 200],
        initialState: {
            paginationModel: tableState.paginationModel?.value || undefined,
            filterModel: (tableState.filter?.filterModel?.value as GridFilterModel) || undefined,
            sortModel: (tableState.sorting?.sortModel?.value as GridSortModel) || undefined,
        },
        getData: async state => {
            return surchargeService.getSurchargeData(state);
        },
    });

    const dataGridProps = getDataGridProps();

    const handleChangeCount = useCallback((newRow: Surcharge) => {
        let changeCount = 0;
        if (newRow.surchargeValueNew.toFixed(2) !== newRow.surchargeValueCurrent.toFixed(2)) changeCount++;

        newRow.changeCount = changeCount;
    }, []);

    const trackEditedRow = useCallback(
        async (newRow: Surcharge, oldRow: Surcharge) => {
            if (JSON.stringify(newRow) !== JSON.stringify(oldRow)) {
                handleChangeCount(newRow);
                await saveSurchargeChanges.mutateAsync({ newRow, oldRow, user });

                // Ensure we are effecting cost rows before starting mass action
                const costs = await costService.getCostsForSurcharge(newRow.surchargeDescription);
                if (costs.length === 0) return;

                const massActionCorrelationId = generateCorrelationId();

                const params: CostMasterMassActionParameters = {
                    field: '',
                    input: 0,
                    mode: '',
                    holdPrice: false, // Just a default value, holdPrice is not used when field does not equal 'baseCostHoldPrice' or 'marketMovementHoldPrice'
                    surcharge: '',
                    updatedBy: user.id,
                    updatedByEmail: user.email,
                    massActionCorrelationId,
                };
                const filterModel: GridFilterModel = {
                    items: [
                        {
                            id: 1,
                            field: 'surcharge',
                            operator: 'equals',
                            value: newRow.surchargeDescription,
                        },
                    ],
                    logicOperator: GridLogicOperator.And,
                };
                await massActionMutation.mutateAsync({ params, filterModel });
                await createEventStatus.mutateAsync({
                    eventType: 'MassAction',
                    eventTrigger: 'MassActionStarted',
                    actor: user.email,
                    correlationId: massActionCorrelationId,
                });
                await triggerAdfMutation.mutateAsync({
                    actor: user.id,
                    eventType: 'application.event.triggered',
                    subject: 'Build and Submit Batch Process - PostMassAction',
                    eventBody: { email: user.email, massActionCorrelationId },
                    correlationId: massActionCorrelationId,
                });
            }
        },
        [
            saveSurchargeChanges,
            handleChangeCount,
            user,
            massActionMutation,
            createEventStatus,
            triggerAdfMutation,
            costService,
        ]
    );

    const processRowUpdate = useCallback(
        (newRow: Surcharge, oldRow: Surcharge) =>
            new Promise<Surcharge>(async resolve => {
                try {
                    await trackEditedRow(newRow, oldRow);
                    resolve(newRow);
                } catch (e) {
                    resolve(oldRow);
                }
            }),
        [trackEditedRow]
    );

    return (
        <>
            {!massActionEnabled && (
                <Stack sx={{ pb: 1 }}>
                    <Alert severity='warning'>
                        <strong>A surcharge mass action is currently processing</strong>
                    </Alert>
                </Stack>
            )}
            <Paper
                sx={{
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                    },
                    height: 'calc(100vh - 270px)',
                    '& .MuiDataGrid-cell--editable': {
                        '& .MuiInputBase-root': {
                            height: '100%',
                        },
                    },
                    '& .cell-warning': {
                        backgroundColor: 'rgba(244, 67, 54, 0.3)',
                    },
                    '& .values-diff': {
                        backgroundColor: 'rgba(245, 213, 39, 0.5) !important',
                    },
                }}
            >
                <CurrencyToggle rates={exchangeRates} setCurrency={setCurrencyType}></CurrencyToggle>
                <DataGridPremium
                    {...dataGridProps}
                    apiRef={apiRef}
                    processRowUpdate={processRowUpdate}
                    slots={{
                        toolbar: SurchargeMasterTableToolbar as GridSlots['toolbar'],
                        loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
                    }}
                    slotProps={{
                        toolbar: {
                            user,
                            state,
                        },
                    }}
                    onColumnVisibilityModelChange={model => tableState.columns.columnVisibilityModel.set(model)}
                    isCellEditable={() => true}
                />
            </Paper>
        </>
    );
}
