import {
    EventData,
    IEventService,
    IFilter,
    IStatusService,
    StatusData,
    StatusResponseData,
} from '@price-for-profit/micro-services';
import { CreateStatusParameters, EventServiceTriggerAdfParameters } from 'shared/types';

export interface IEventStatusService {
    triggerADFPipeline(payload: EventServiceTriggerAdfParameters): Promise<EventData>;
    getStatusByCorrelationId(correlationId: string): Promise<StatusResponseData[]>;
    createStatus(payload: CreateStatusParameters): Promise<StatusResponseData>;
}

export class EventStatusService implements IEventStatusService {
    constructor(private eventService: IEventService, private statusService: IStatusService, private clientId: string) {}

    async triggerADFPipeline(payload: EventServiceTriggerAdfParameters): Promise<EventData> {
        return await this.eventService.triggerADFPipeline({
            clientId: this.clientId,
            ...payload,
        });
    }

    async createStatus(payload: CreateStatusParameters): Promise<StatusResponseData> {
        const response = await this.statusService.create({
            clientId: this.clientId,
            ...payload,
        });

        return response;
    }

    async getStatusByCorrelationId(correlationId: string): Promise<StatusResponseData[]> {
        const baseFilter: IFilter<StatusData> = {
            logicalOperator: 'and',
            filters: [{ property: 'correlationId', operator: 'eq', value: correlationId }],
        };

        const response = await this.statusService.getCollection({
            clientId: this.clientId,
            collectionFilter: baseFilter,
            pageSize: 1000,
        });

        return response.data;
    }
}
