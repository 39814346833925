import { GridFilterItem, GridFilterModel, GridLogicOperator } from '@mui/x-data-grid-premium';

export const getPriceMasterFilterModelInitialState = (user: drive.UserInfo | null): GridFilterModel => {
    const items: GridFilterItem[] = [];
    if (user && user.multiMarket) {
        items.push({
            id: 1,
            field: 'multiMarket',
            operator: 'equals',
            value: user.multiMarket,
        });
    } else if (user && user.office) {
        items.push({
            id: 1,
            field: 'so',
            operator: 'equals',
            value: user.office,
        });
    }
    const model: GridFilterModel = {
        items,
        logicOperator: GridLogicOperator.And,
    };
    return model;
};

export const getPriceSummaryColumnVisibilityInitialState = (user: drive.UserInfo | null): { [key: string]: any } => {
    let priceSummaryColumnVisibilityInitialState = {
        actions: true,
        baseCostChange: true,
        baseCostCurrent: true,
        baseCostHoldPrice: true,
        baseCostLastChange: true,
        baseCostMode: true,
        baseCostNew: true,
        baseLbs: true,
        baseLbsRange: true,
        bundles: true,
        bw: true,
        bwDesc: true,
        changeCount: true,
        bookPriceChange: true,
        bookPriceCurrent: true,
        bookPriceLastChange: true,
        bookPriceMaxChangeWarning: false,
        bookPriceMode: true,
        bookPriceNew: true,
        createdBy: false,
        createdDate: false,
        customerId: true,
        finalBaseCost: true,
        finalBookPrice: true,
        finalFloorPrice: true,
        finalMarketMovement: true,
        finalTargetMargin: true,
        floorMarginCurrent: true,
        floorMarginNew: true,
        floorPriceChange: true,
        floorPriceCurrent: true,
        floorPriceMinWarning: false,
        floorPriceNew: true,
        floorVsBookPriceDiff: true,
        form: true,
        hasWarnings: false,
        id: false,
        index: true,
        insightRecPrice: true,
        itemNumber: true,
        marketMovementChange: true,
        marketMovementCurrent: true,
        marketMovementHoldPrice: true,
        marketMovementLastChange: true,
        marketMovementMode: true,
        marketMovementNew: true,
        marketPriceRecommendation: true,
        multiMarket: true,
        nonMaterialCost: true,
        nonMaterialCostAdj: true,
        pcpFreightCost: true,
        priceBook: true,
        product: true,
        productCategory: true,
        replacementCost: true,
        rollingTwelveMonthsRevenue: true,
        shipToState: true,
        shipToZipCode: true,
        smallOrderAdder: true,
        so: true,
        targetMarginAdj: true,
        targetMarginChange: true,
        targetMarginCurrent: true,
        targetMarginLastChange: true,
        targetMarginMaxChangeWarning: false,
        targetMarginMinWarning: false,
        targetMarginNew: true,
        threePc: true,
        updatedBy: false,
        updatedByEmail: false,
        updatedDate: false,
        warnings: true,
        marketPriceRecommendationWarning: true,
        revenueTier: true,
        marginModelAutoload: true,
        marketTmRecommendation: true,
    };

    const priceSummaryColumnVisibilityCXRProcurement = {
        baseCostChange: false,
        baseCostCurrent: false,
        baseCostHoldPrice: false,
        baseCostLastChange: false,
        baseCostMode: false,
        baseCostNew: false,
        baseLbs: false,
        bundles: false,
        changeCount: false,
        bookPriceChange: false,
        bookPriceMode: false,
        bookPriceNew: false,
        customerId: false,
        finalBaseCost: false,
        finalFloorPrice: false,
        finalMarketMovement: false,
        floorMarginNew: false,
        floorMarginAllInCurrent: true,
        floorPriceChange: false,
        floorPriceNew: false,
        floorVsBookPriceDiff: false,
        index: false,
        insightRecPrice: false,
        itemNumber: false,
        marketMovementChange: false,
        marketMovementCurrent: false,
        marketMovementHoldPrice: false,
        marketMovementLastChange: false,
        marketMovementMode: false,
        marketMovementNew: false,
        marketTmRecommendation: false,
        nonMaterialCost: false,
        nonMaterialCostAdj: false,
        pcpFreightCost: false,
        priceBook: false,
        productCategory: false,
        shipToState: false,
        shipToZipCode: false,
        smallOrderAdder: false,
        targetMarginAdj: false,
        targetMarginChange: false,
        targetMarginLastChange: false,
        targetMarginNew: false,
        threePc: false,
        warnings: false,
        marketPriceRecommendationWarning: false,
    };

    const priceSummaryColumnVisibilityCXMCOE = {
        baseCostChange: false,
        baseCostCurrent: false,
        baseCostHoldPrice: false,
        baseCostLastChange: false,
        baseCostMode: false,
        baseCostNew: false,
        baseLbs: false,
        bundles: false,
        changeCount: false,
        bookPriceChange: false,
        bookPriceMode: false,
        customerId: false,
        finalBaseCost: false,
        finalFloorPrice: false,
        finalMarketMovement: false,
        finalFloorMarginAllIn: false,
        floorPriceChange: false,
        floorVsBookPriceDiff: false,
        floorMarginAllInCurrent: true,
        floorMarginAllInNew: true,
        index: false,
        insightRecPrice: false,
        itemNumber: false,
        marketMovementChange: false,
        marketMovementCurrent: false,
        marketMovementHoldPrice: false,
        marketMovementLastChange: false,
        marketMovementMode: false,
        marketMovementNew: false,
        marketPriceRecommendationWarning: false,
        nonMaterialCost: false,
        nonMaterialCostAdj: false,
        pcpFreightCost: false,
        priceSyncFlag: false,
        priceSyncAnchor: false,
        priceBook: false,
        productCategory: false,
        previousMarketPriceRecommendation: false,
        previousMarketTMRecommendation: false,
        shipToState: false,
        shipToZipCode: false,
        smallOrderAdder: false,
        targetMarginAdj: false,
        targetMarginChange: false,
        targetMarginLastChange: false,
        threePc: false,
        marketTmRecommendation: false,
        warnings: false,
    };

    if (user && (user.cxr || user.procurement || user.canada_procurement)) {
        priceSummaryColumnVisibilityInitialState = {
            ...priceSummaryColumnVisibilityInitialState,
            ...priceSummaryColumnVisibilityCXRProcurement,
        };
    }

    if (user && (user.cxm_mmgm || user.coe)) {
        priceSummaryColumnVisibilityInitialState = {
            ...priceSummaryColumnVisibilityInitialState,
            ...priceSummaryColumnVisibilityCXMCOE,
        };
    }

    return priceSummaryColumnVisibilityInitialState;
};

export const getPriceMasterPinnedColumnInitialState = (user: drive.UserInfo | null) => {
    if (user && (user.cxm_mmgm || user.cxr)) {
        return {
            left: ['actions', 'bwDesc', 'bw', 'so'],
            right: [],
        };
    }
    return {
        left: ['actions', 'priceBook', 'multiMarket', 'so', 'bw'],
        right: [],
    };
};
